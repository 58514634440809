import type { GetEmployerInvoicesParams } from "@js/apps/invoices/types";
import { processEmployerInvoicesParams } from "@js/apps/invoices/utils";
import { parseListQueryParam } from "@js/utils/rtkq";

import type { EmployerInvoicesTableColumnField } from "./types";

const COLUMNS_FOR_EXPORT_MAP: Partial<
  Record<EmployerInvoicesTableColumnField, string>
> = {
  date_from: "start_date",
  date_to: "end_date",
  item_amount: "line_item_amount",
  freelancer__user__email: "freelancer_email",
};

export const processColumnsParamForExport = (
  columns: EmployerInvoicesTableColumnField[],
) => {
  const mappedColumns = columns.map((column) => {
    const mappedColumn = COLUMNS_FOR_EXPORT_MAP[column] ?? column;

    return mappedColumn;
  });

  return parseListQueryParam(mappedColumns);
};

export const getExportEmployerInvoicesParams = ({
  filters,
  columns,
}: {
  filters: GetEmployerInvoicesParams;
  columns: EmployerInvoicesTableColumnField[];
}) => {
  const params = processEmployerInvoicesParams(filters);
  const columnsParam = processColumnsParamForExport(columns);

  delete params.page;

  return { ...params, columns: columnsParam, format: "csv" };
};
